const getWorkspaceURL = () => {
  switch (window.location.host) {
    case 'localhost:3000': {
      return `http://localhost:5050/${window.tenancy}`
    }
    case 'test.go.knackly.io': {
      return `https://api.test.go.knackly.io/${window.tenancy}`
    }
    case 'go.knackly.io': {
      return `https://api.knackly.io/${window.tenancy}`
    }
    case 'new.knackly.io': {
      return `https://api.knackly.io/${window.tenancy}`
    }
    case 'live.lightningdocs.com': {
      return `https://api.knackly.io/${window.tenancy}`
    }
    default: {
      return `https://${window.location.host}/${window.tenancy}`
    }
  }
}

export default getWorkspaceURL
