import axios from 'axios'
import getVisitorId from './getVisitorId'

export default async token => {
  try {
    const visitorId = await getVisitorId()
    let { data: { isAuth, user, refreshToken } } = await axios.post(
      '/api/isAuth/',
      { token, visitorId })
    return { auth: isAuth, user, refreshToken }
  } catch (e) {
    return false
  }
}
